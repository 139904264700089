.frame-wrapper {
    visibility: hidden;
}

.button-icon {
    font-size: 3rem;
}



.blink-animation {
    animation: blink-animation 0.7s steps(10, start) infinite;
    -webkit-animation: blink-animation 0.7s steps(10, start) infinite;
}

@keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }
  @-webkit-keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }