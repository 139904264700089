
.logo-text {
  margin-left: -1.5rem;
  letter-spacing: 0.3em;
}
.navLink {
  /* border-bottom: 1px dashed black; */
}
.navLink:hover {
  color:#9ba1af
}
