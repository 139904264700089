/* @import url('./fonts/font.css'); */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Gill Sans';
    src: url('../public/fonts/GillSans.ttc') format("ttc");
  }
  /* @font-face {
    font-family: 'Gil Sans Light';
    src: url('../public/fonts/GillSansLight.otf') format("otf");
  } */

}


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
